import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

// Configuração i18n
const i18nConfig = {
  debug: false,
  load: "currentOnly",
  fallbackLng: 'en-US',
  interpolation: {
    escapeValue: false,
  },
};

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(i18nConfig); // Usa nossas configurações

export default i18n;
